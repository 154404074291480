@font-face {
    font-family: IRANSans;
    src: url("./assets/IRANSansWeb.ttf") format("truetype");
}
*{
    /*font-family: IRANSans!important;*/
}
body{
    direction: rtl;
    /*overflow: hidden;*/
}

/* width */
::-webkit-scrollbar {
    /*width: 0;*/
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}